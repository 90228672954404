import React, { ReactNode } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { logout } from '@dvag/dfs-orchestrator-client/messengers';
import { Error403, Error404, ErrorCrashedApp } from '@dvag/dfs-ui-blocks/components';
import { FallbackError } from './type/FallbackError';
import { getAppInsights } from './service/getAppInsights';
import { isSalesforceWishesAndGoals } from './utils/util';

export type RouterErrorBoundaryProps = {
  children: ReactNode;
};
export const RouterErrorBoundary = ({ children }: RouterErrorBoundaryProps) => {
  const location = useLocation();
  const { appInsights } = getAppInsights();

  const isUnauthorized = (error: FallbackError) => error?.response?.status === 401;

  const isForbidden = (error: FallbackError) =>
    [error?.response?.status, error?.cause].includes(403);

  const isNotFound = (error: FallbackError) =>
    [error?.response?.status, error.cause, error?.response?.errors?.[0]?.code].includes(404);

  const fallbackComponent = ({ error }: FallbackProps) => {
    const isSalesforce = isSalesforceWishesAndGoals(location.pathname);
    const showButton = !isSalesforce;
    if (isForbidden(error)) return <Error403 showActionButton={showButton} />;
    if (isNotFound(error)) return <Error404 showActionButton={showButton} />;
    return <ErrorCrashedApp isSalesforce={isSalesforce} showActionButton={showButton} />;
  };

  const handleError = (error: FallbackError) => {
    appInsights.trackException({
      exception: error as Error,
      severityLevel: 3,
    });
    if (isUnauthorized(error)) logout();
  };

  return (
    <ErrorBoundary
      FallbackComponent={fallbackComponent}
      onError={handleError}
      resetKeys={[location]}
    >
      {children}
    </ErrorBoundary>
  );
};
